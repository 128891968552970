import styled from 'styled-components';
import {theme} from '../../theme';

const menuPadding = "2rem";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${theme.primaryLight};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: ${menuPadding};
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  transition: transform .2s ease-out;
  min-width: 30rem;
  
  &[aria-hidden="false"] {
      transition: box-shadow .5s;
      box-shadow: 2px 2px 23px 0px rgba(0, 0, 0, .5);
  }
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100%;
      opacity: 0;
      
       &[aria-hidden="false"] {
          opacity: 1;
          transition: opacity .2s;
      }
   }

  p.mobile-title {
    display: none;
    font-size: 2rem;
    text-align: center;
    color: ${theme.primaryDark} !important;
    font-weight: normal;
    font-family: 'Ropa Sans', sans-serif;
    position: absolute;
    top: 0;
    width: calc(100% - ${menuPadding} * 2);
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      display: block;
    }
  }

  a {
  font-family: 'Open Sans', sans-serif;
  //font-family: 'Ropa Sans', sans-serif;
    font-size: 2rem !important;
    text-transform: capitalize;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: ${theme.primaryDark} !important;
    text-decoration: none;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }
    
    :hover {
      span {
        :before {
          width: 100%;
          opacity: 1;
        }
      }
    }
    
    span {
      text-align: center;
      margin: 0 auto;
      padding: 0;
      color: ${theme.primaryDark}
      transition: $animate;
      position: relative;
      :before,
      :after {
        content: "";
        position: absolute;
        bottom: -.5rem;
        width: 0px;
        height: .2rem;
        margin: 0px 0 0;
        transition: $animate;
        transition-duration: 0.5s;
        opacity: 0;
        left: 0;
        background-color: ${theme.textBlue};
      }
    }
}
`;
