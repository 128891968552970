import styled from 'styled-components';
import {theme} from '../../theme';

const getAccordionStateForBody = (viewAll) => viewAll === 'true' ? 'block' : 'none';

export const StyledAccordion = styled.div`
  .accordion__header {
    border-bottom: 1px solid #cfd0d2;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .3s ease-in-out;
    
    &:hover {
        background-color: #f5f5f5;
    }
    
    .title {
      display: flex;
    }
    
    .fa-ban {
      color: ${theme.warning}
    }
    
    .action {
    display: flex;
    
    .svg-inline--fa {
        color: ${theme.primaryDark}
      }
    }
    
    .svg-inline--fa {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-self: flex-start;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    strong {
      display: flex;
      font-size: 1.9rem;
      align-self: center;
    }
  }
  
  .accordion__body {
    display: ${(props) => getAccordionStateForBody(props.viewAll)};
    padding-left: 2.5rem;
    
    p {
      color: #717477;
    }
  }
`;
