import React from 'react';
import {StyledSelect} from "./Select.styled";

const Select = ({label, question, options, updateAnswer, openLightbox, placeholder}) => {
    let choice = options ? options[0].value : '';

    function update(value) {
        choice = value;
        updateAnswer(question, choice)
    }

    function open(id) {
        openLightbox(id)
    }

    return (
        <StyledSelect>
            <label htmlFor="">{label}</label>

            <div className="select">
                <div className="select__list">
                <select  defaultValue="-" onChange={(e) => update(e.target.value)}>
                    <option key="-" value="-" disabled>{placeholder}</option>
                    {options.map((option) => <option key={option.value} value={option.value}>{option.name}</option>)}
                </select>
                </div>
            </div>
        </StyledSelect>
    )
};

export default Select;
