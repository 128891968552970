import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledHeader = styled.div`
  background-color: ${theme.primaryDark};
  position: relative;
  padding: .35rem;
  height: ${theme.headerHeight};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: calc(${theme.headerHeight} - .75rem);
  }
  vertical-align: middle;
  
  h1 {
    color: ${theme.primaryLight};
    padding: 0;
    margin: 0;
    line-height: calc(${theme.headerHeight} - 1rem);
  }
`;
