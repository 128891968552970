import styled from 'styled-components';
import {theme} from '../../theme';
const spinnerSize = '5rem';

export const StyledSpinner = styled.div`
  display: inline-block;
  width: ${spinnerSize};
  height: ${spinnerSize};
  margin: 0 auto;
  opacity: .66;
  
  :after {
  content: " ";
  display: block;
  width: ${spinnerSize};
  height: ${spinnerSize};
  margin: .8rem;
  border-radius: 50%;
  border: .5rem solid ${theme.primaryDark};
  border-color: ${theme.primaryDark} transparent ${theme.primaryDark} transparent;
  animation: lds-dual-ring 1.6s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;
