import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledSelect = styled.div`
  max-width: 25rem;
  position: relative;
  margin: 0 auto;

  .select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin: 1rem 0 3rem 0;
    //margin: 1rem 1rem 3rem 1rem;

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      color: ${theme.textBlue};
      border: 1.5px ${theme.textBlue} solid;
      border-radius: 50%;
      position: absolute;
      right: -2.5rem;
      top: .3rem;
      cursor: pointer;
    }
    
    &__list {
      position: relative;
      width: 100%;
      
        select {
        font-family: 'Ropa Sans', sans-serif;
        display: flex;
        font-size: 1.8rem;
        color: ${theme.textBlue};
        padding: .5em 1.4em .5em .8em;
        border: 1px solid ${theme.textBlue};
        border-radius: 0;
        appearance: none;
        background-color: #fff;
        
        width: 100%;
    }
    
    &:after {
            content: '';
            border: solid ${theme.textBlue};
            position: absolute;
            right: 1.5rem;
            top: 35%;
            border-width: 0 3px 3px 0;
            padding: 3px;
            width: 6px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            pointer-events: none;
        }
    }

    ::-ms-expand {
        display: none;
    }
    
    :hover {
        border-color: #888;
    }
    
    :focus {
        outline: none;
    }
    
    select option {
        font-weight:normal;
    }
    .select__info {
        display:none;
    }
}
`;
