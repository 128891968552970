import React from 'react';
import {StyledInput} from "./Input.styled";

const Input = ({label, type, question, options, value, updateAnswer}) => {
    // let choice = options ? options[0].value : '';

    function update(value) {
        // choice = value;
        updateAnswer(question, value)
    }

    return (
        <StyledInput>
            <label htmlFor="">{label}</label>
            <input type={type === 'email' ? 'email' : 'text'} value={value} onChange={(e) => update(e.target.value)}/>
            {/*<div className="select">*/}
            {/*    <select onChange={(e) => update(e.target.value)}>*/}
            {/*        {options.map((option) => <option key={option.value} value={option.value}>{option.name}</option>)}*/}
            {/*    </select>*/}
            {/*</div>*/}
        </StyledInput>
    )
};

export default Input;
