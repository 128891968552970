import React from 'react';
import {StyledAccordion} from "./Accordion.styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faCheckCircle, faInfoCircle, faBan} from "@fortawesome/pro-regular-svg-icons";

const Accordion = ({viewAll, valid, title, description, children}) => {
        return (<StyledAccordion viewAll={viewAll}>
        <div className="accordion__header">
            <div className="title">
                <FontAwesomeIcon
                    icon={(valid !== 'trap' ? valid ? faCheckCircle : faBan : faInfoCircle)}/><strong>{title}</strong>
            </div>
            <div className="action">
                <FontAwesomeIcon icon={(viewAll === 'true') ? faAngleDown : faAngleUp}/>
            </div>
        </div>
        <div className="accordion__body"><p>{description}</p></div>
    </StyledAccordion>)
}
;

export default Accordion;
