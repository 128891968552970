import React from 'react';
import {StyledHeader} from "./Header.styled";
import MenuWrapper from "../MenuWrapper";
import {withTranslation} from "react-i18next";

class Header extends React.Component {

  deferredPrompt;

constructor() {
  super();
  this.handleInstallation = this.handleInstallation.bind(this)

}

componentDidMount() {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    // e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    // this.deferredPrompt.prompt()
    return false;
    // Update UI notify the user they can install the PWA
  //   s<howInstallPromotion();
  });
// window.dispatchEvent(new Event('beforeinstallprompt'))

window.addEventListener('appinstalled', (evt) => {
  // Log install to analytics
  console.log('INSTALL: Success');
});
}

handleInstallation(e) {
    // Hide the app provided install promotion
      // hideMyInstallPromotion();
      // Show the install prompt
      this.deferredPrompt.prompt();
       // Wait for the user to respond to the prompt
       this.deferredPrompt.userChoice.then((choiceResult) => {
         if (choiceResult.outcome === 'accepted') {
           console.log('User accepted the install prompt');
         } else {
           console.log('User dismissed the install prompt');
         }
       });
}

render() {
    const {t} = this.props;
  return (
    <StyledHeader>
        <MenuWrapper />
        <h1>{t('site.title')}</h1>
    </StyledHeader>
)
}
  
}

export default withTranslation()(Header);
