import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import styled from "styled-components";
import Page from "../Page";
import {slideInRight, slideOutRight} from "../../animations/slide";
import {theme} from "../../theme";
import { useTranslation } from "react-i18next";

const AboutPageElm = styled(Page)`
padding: 2rem;
  &.page-enter {
    animation: ${slideInRight} 0.2s forwards ease-in-out;
  }
  &.page-exit {
    animation: ${slideOutRight} 0.2s forwards ease-in-out;
  }
  
  .container {
      max-width: 96rem;
      margin: 0 auto;
  }
  
  h2 {
  margin-bottom: 6rem;
  }
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
  
  strong {
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
    width: 100%;
    margin: 3rem 0;
  }
  
  ul li {
    color: ${theme.textGrey};
  }
  
  .partner-logo-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 10rem;
  }
  
  .partner-logo {
    max-width: 8rem;
    display: flex;
    align-self: center;
    @media (min-width: ${({ theme }) => theme.mobile}) {
        max-width: 12rem;
    }
  }
`;

function AboutPage() {
    const {t} = useTranslation();
    return (
        <AboutPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={2}>
                    </Col>
                    <Col xs={12} sm={8}>
                        <h2>{t('pages.contact.title')}</h2>
                        <p>{t('pages.contact.head1')} <a href="mailto:info@werkenoverdegrens.eu">info@werkenoverdegrens.eu</a>, <a
                            href="https://www.facebook.com/grensarbeid" target="_blank">facebook</a> {t('pages.contact.head2')}</p>
                    </Col>
                    <Col xs={0} sm={2}>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <Row>
                            <Col xs={4} >
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href={t("pages.results.aclvb.url")} title={t("pages.results.aclvb.name")}>
                                        <img className="partner-logo" src={t("pages.results.aclvb.logo")} alt="Logo ACLVB"/>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href={t("pages.results.acv.url")} title={t("pages.results.acv.name")}>
                                        <img className="partner-logo" src={t("pages.results.acv.logo")} alt="Logo ACV"/>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href={t("pages.results.abvv.url")} title={t("pages.results.abvv.name")}>
                                        <img className="partner-logo" src={t("pages.results.abvv.logo")} alt="Logo ABVV"/>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href="https://www.fnv.nl/grensarbeiders" title="FNV">
                                        <img className="partner-logo" src={'/images/fnv.svg'} alt="Logo FNV"/>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href="https://www.cnv.nl/" title="CNV">
                                        <img className="partner-logo" src={'/images/cnv.svg'} alt="Logo CNV"/>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <a target="_blank" href="https://www.unie.nl/" title="De Unie">
                                        <img className="partner-logo" src={'/images/de-unie.svg'} alt="Logo De Unie"/>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </AboutPageElm>
    );
}

export default AboutPage
