export const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 76, // rem
      // md: 61, // rem
      lg: 96  // rem
    },
    breakpoints: {
      xs: 0,  // em
      sm: 48, // em
      md: 64, // em
      lg: 75  // em
    }
  },
  primaryDark: '#0E3C72',
  primaryLight: '#FACA22',
  primaryHover: '#343078',
  textGrey: '#636568',
  textBlue: '#10427C',
  valid: '#48B41A',
  error: '#950A0B',
  warning: '#FAA429',
  iphoneSE: '320px',
  mobile: '576px',
  headerHeight: '5.6rem',
  footerHeight: '7.5rem',
  borderRadius: '1.2rem'
};
