import {Button} from "../../components";
import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Page from "../Page"
import {Col, Row} from "react-styled-flexboxgrid";
import PageGrid from "../../components/PageGrid";
import { useTranslation } from "react-i18next";

const MailConfirmPageElm = styled(Page)`
padding: 2rem;
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
`;

function MailConfirmPage() {
    const {t} = useTranslation();
    return (
        <MailConfirmPageElm>
            <PageGrid>
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.mailConfirm.title')}</h2>
                        <p className="text-center">
                            {t('pages.mailConfirm.startAgain')}
                        </p>
                        <br/><br/>
                        <br/><br/>
                        <div className="text-center">
                            <Button large={true} fullWidth={true} start={true}>
                                <Link to="/start">
                                    <span>{t('pages.mailConfirm.start')}</span>
                                </Link>
                            </Button>
                        </div>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </PageGrid>

        </MailConfirmPageElm>
    );
}

export default MailConfirmPage
