import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledLightbox = styled.div`
    @keyframes fade {
      from {opacity: 0;}
      to {opacity: 1;}
    }
    display: ${props => props.visible ? 'flex' : 'none'};
    opacity: ${props => props.visible ? '1' : '0'};
     position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    background-color: rgba(14, 60, 114, .5);
    //background-color: tranparentize(${theme.primaryDark}, .5);
    align-items: center;
    justify-content: center;
    
    .content {
    animation-name: fade;
    animation-duration: .5s;
        background-color: white;
        width: 40rem;
        max-width: 90vw;
       border-radius: .5rem;
       padding: 2rem;
           position: relative;
    }
   
    .close {
        color: ${theme.primaryDark};
        text-align: right;
        margin-top: 5vh;
        font-weight: bold;
        font-size: 3rem;
        cursor: pointer;
        position: absolute;
        top: -4rem;
        right: 1rem;
    }
    
    .title {
      color: ${theme.textBlue};
      font-family: 'Ropa sans', sans-serif;
      font-size: 2.8rem;
    }
    .body {
      color: ${theme.textBlue};
    }
}
`;
