import styled from "styled-components";
import {Button} from "../../components";
import {Link} from "react-router-dom";
import React from "react";
import Page from "../Page"
import PageGrid from "../../components/PageGrid";
import {Col, Row} from "react-styled-flexboxgrid";
import RadioButton from "../../components/RadioButton";
import Spinner from "../../components/Spinner";
import * as QuestionsService from "../../services/questions.service";
import {withTranslation, useTranslation} from "react-i18next";
const QuizPageElm = styled(Page)`
  padding-bottom: 4rem;

  .navigation {
  margin-top: 6rem;
     
    @media (max-width: ${({ theme }) => theme.mobile}) {
      position: fixed;
      width: 100vw;
      left: 0;
      bottom: 0;
      background-color: white;
      padding: 2rem;
    }
  }
`;

class QuizPage extends React.Component {
    START_LEVEL = 0;

    constructor(props) {
        super(props);
        const { i18n } = this.props;

        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            previousQuestion: props.previousQuestion,
            question: {},
            answer: '',
            isEnd: false,
            language: i18n.language
        };

        const language = queryParams.get('language');

        this.getQuestion(this.props.match.params.id, language);
    }

    getQuestion(answerId = '', language = 'nl') {
        if(answerId) {
            QuestionsService.getQuestionByAnswer(answerId, language).then(({question}) => this.parseQuestion(question));
        } else {
            QuestionsService.getQuestions(language).then(({question}) => this.parseQuestion(question));
        }
    }

    updateQuestion(answer, isEnd, scoreSet) {
        if (isEnd) {
            this.props.updateScoreSet(scoreSet);
        }
        this.setState({isEnd, answer});
    }

    updateAnswers() {
        this.props.updateAnswers(this.state.answer);
    }

    removeLastAnswer() {
        this.props.updateAnswers(false);
    }

    getTitle() {
        if(process.env.NODE_ENV === 'development') {
            return (this.props.jobs >= 2 ? `Job ${this.props.currentJob} - ` : '') + (this.state.question.title || '');
        }
        return (this.props.jobs >= 2 ? `Job ${this.props.currentJob}` : '');
    }

    parseQuestion(question) {
        if(question) {
            this.setState(
                {
                    question: {
                        title: question.admin_description,
                        label: question.question_tc,
                        body: question.sub_question_tc,
                        level: question.level,
                        key: question.id,
                        answers: question.answers.sort((answer_a, answer_b) => answer_a.level - answer_b.level).map(answer => (
                            {
                                value: answer.id,
                                name: answer.answer_tc,
                                isEnd: answer.is_end,
                                scoreSet: answer.score_set
                            }
                        ))
                    }
                }
            );
        }
    }

    checkCurrentJob() {
        console.log(this.props.jobs);
        if (parseInt(this.props.currentJob, 10) === parseInt(this.props.jobs, 10)) {
            this.props.history.push('/result')
        } else {
            this.props.setCurrentJob((this.props.currentJob + 1));
            this.props.history.push('/quiz')
        }
    }

    render() {
        let content;
        const { t } = this.props;
        if(this.state.question && this.state.question.key) {
            const navigation = (<> {
                (this.state.isEnd ? (
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <Button  className="slide-up-fade-in btn" large={true} fullWidth={true}>
                                    <div className="a" onClick={() => {
                                        this.checkCurrentJob();
                                        console.log(this.props);
                                    }}>
                                        <span>{t('pages.quiz.complete')} →</span>
                                    </div>
                                </Button>
                                <br/>
                                <br/>
                                <Link className={'link'} to={`/quiz/${this.state.previousQuestion}?language=${this.state.language}`} onClick={(event) => {
                                    window.history.back();
                                    event.stopPropagation();
                                }}>
                                    ← {t('pages.quiz.previous')}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <Button disabled={!(!!this.state.answer)} large={true} fullWidth={true} className={"btn"}>
                                    <Link to={`/quiz/${this.state.answer}?language=${this.state.language}`} className="slide-up-fade-in" onClick={() => {this.updateAnswers()}}>
                                        <span>{t('pages.quiz.next')} →</span>
                                    </Link>
                                </Button>
                                <br/>
                                <br/>
                                <Link className={'link'} to={`/quiz/${this.state.previousQuestion}?language=${this.state.language}`} onClick={(event) => {
                                    this.removeLastAnswer();
                                    window.history.back();
                                    event.stopPropagation();
                                }}>
                                    ← {t('pages.quiz.previous')}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                ))
            } </>);


            content = (
                <>
                    { (this.state.question.answers || []).map((answer,i) =>
                        <RadioButton
                            key={`btn_${answer.name}`}
                            name={answer.name}
                            index={i}
                            value={answer.value}
                            isSelected=""
                            updateValue={() => {this.updateQuestion(answer.value, answer.isEnd, answer.scoreSet)}}/>
                    ) }

                    <div className="navigation">
                        {navigation}
                    </div>
                </>
            );

        } else {
            content = <Spinner/>
        }

        return (
            <QuizPageElm>
                <PageGrid className="isQuiz">

                    <h2>
                        {this.state.question.label}
                    </h2>
                    <p>{this.state.question.body}</p>

                    {content}

                </PageGrid>
            </QuizPageElm>
        );
    }
}

export default withTranslation()(QuizPage);
