import styled from 'styled-components';
import { theme } from '../../theme';

const getButtonColor = (secondary, start) => {
  if (secondary) {
    return theme.primaryDark;
  }

  if (start === 'true') {
    return theme.primaryDark;
  }

  return "white";
};

const getButtonBackgroundColor = (secondary, start) => {
  if (secondary) {
    return "white";
  }

  if (start === 'true') {
    return theme.primaryLight;
  }

  return theme.primaryDark;
};

export const StyledModal = styled.div`
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.25);
    z-index: 1999;

.content {
  position: fixed;
  top: 10vh;  
  left: 5vw; 
  z-index: 20000; 
  width: 90vw;
  height: 80vh;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius};

  .pwa-icon {
    align-self: center;
    display: flex;
    border: 2px solid ${theme.primaryDark};
    border-radius: 2rem;
  }

  .share-icon {
    width: 2.5rem;
    height: auto;
    vertical-align: middle;
  }

  .button {
    margin-top: 2rem;
  }
}
`;
