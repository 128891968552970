import React from 'react';
import FocusLock from "react-focus-lock";
import {Burger, Menu} from "../index";
import { useOnClickOutside } from './../../hooks';
import { useState, useRef } from 'react';



const MenuWrapper = () => {

    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    return (
        <div ref={node} className="menu__wrapper">
            <FocusLock disabled={!open}>
                <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                <Menu open={open} setOpen={setOpen} id={menuId} />
            </FocusLock>
        </div>
    )
};

export default MenuWrapper;
