import styled from "styled-components";
import {slideInRight, slideOutLeft} from "../../animations/slide";

const Page = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  .text--center {
    max-width: 400px;
    margin: 1.5rem auto 1.25rem auto;
  }
  
  &.page-enter {
    animation: ${slideInRight} 0.2s forwards ease-in-out;
    height: 100vh;
  }
  &.page-exit {
    animation: ${slideOutLeft} 0.2s forwards ease-in-out;
    height: 100vh;
  }
`;

export default Page;
