export const postSession = (data) => {
    const url = `${process.env.REACT_APP_API_URL}/sessions/`;
    return callApi(url, data)
};

const callApi = (url, data, options) => {
    const DEFAULT_OPTIONS =  {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };
    return fetch(url, {...DEFAULT_OPTIONS, ...options} ).then((res) => res.json());
};
