export const getOrganisationByPostalCode = (postalcode) => {
    //const url = `https://${process.env.REACT_APP_API_URL}/organisations/${postalcode}`;
    const url = `${process.env.REACT_APP_API_URL}/organisations/${postalcode}`;
    return callApi(url)
};

export const getParameterCountries = (language) => {
    //const url = `http://${process.env.REACT_APP_API_URL}/parameters/countries?language=${language}`;
    const url = `${process.env.REACT_APP_API_URL}/parameters/countries?language=${language}`;
    console.log(url);
    return callApi(url)
};

const callApi = (url, options) => {
    const DEFAULT_OPTIONS =  {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return fetch(url, {...DEFAULT_OPTIONS, ...options} ).then((res ) => res.json());
};
