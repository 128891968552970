import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import GenericPageElm from "../Styling/generic-page";

function CookiePolicyPage() {
    return (
        <GenericPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>Over ons</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin orci risus, faucibus quis nulla vel, porta lacinia justo. Morbi scelerisque vehicula nisl et vehicula. Suspendisse potenti. Fusce ac vehicula eros. Pellentesque sed tellus eget augue rutrum tempus. Proin eu semper enim. Phasellus facilisis neque tristique vehicula aliquam. Curabitur dignissim interdum dolor, quis tincidunt sem rutrum nec.</p>
                        <p><img src={'/images/work.jpeg'} alt="Illustratie"/></p>
                        <p>Vivamus euismod erat rhoncus blandit porta. Cras in auctor massa. Duis tincidunt lectus ac porttitor euismod. Sed vitae semper arcu. Sed ac sollicitudin sapien. Ut vestibulum libero et scelerisque finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat est, sagittis eget augue tempor, finibus porttitor massa. Nam sodales id lorem ac lacinia. Sed lobortis tristique elit eu consectetur. Nulla facilisi. Cras nisl ex, porttitor id justo id, gravida interdum lacus. Vestibulum consequat augue lacinia, eleifend justo non, efficitur eros.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin orci risus, faucibus quis nulla vel, porta lacinia justo. Morbi scelerisque vehicula nisl et vehicula. Suspendisse potenti. Fusce ac vehicula eros. Pellentesque sed tellus eget augue rutrum tempus. Proin eu semper enim. Phasellus facilisis neque tristique vehicula aliquam. Curabitur dignissim interdum dolor, quis tincidunt sem rutrum nec.</p>
                        <p><img src={'/images/work.jpeg'} alt="Illustratie"/></p>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </GenericPageElm>
    );
}

export default CookiePolicyPage
