import { createGlobalStyle } from 'styled-components'
import {theme} from "./theme";

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 10px;
  }
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }
  
  *:focus, *:target, *:active {
    user-select: none;
    outline:none;    
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }

  body {
    color: ${({ theme }) => theme.primaryLight};
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: 'Ropa Sans', sans-serif;
  }

  h1 {
    font-size: 2.8rem;
    text-align: center;
    color: ${theme.primaryLight};
    font-weight: normal;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 2rem;
    }
  }
  
  h2, h3 {
    font-size: 2.5rem;
    color: ${theme.textBlue};
    text-align: center;
    margin: 1.5rem 0 1.5rem 0;
  }
  
  h4 {
    color: ${theme.primaryDark};
    font-size: 2.1rem;
    margin: 1.5rem 0;
  }
  
  p, a, li, .a, i {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }
    
    p, a, li, .a {
    font-size: 1.6rem;
    line-height: 1.6;
  }
  
  i {
    font-size: 1.3rem;
    line-height: 1.4;
  }
  
  p, span, i {
    color: ${theme.textGrey};
  }
  
  a {
    color: ${theme.primaryDark};
  }
  
  a:visited {
    color: ${theme.primaryDark};
  }

  strong {
    color: ${theme.primaryDark};
  }

   .blue-text {
      color: ${theme.textBlue};
   }
   
   .yellow-text {
      color: ${theme.primaryLight};
      font-weight: 600;
   }

  img {
    border-radius: 5px;
    height: auto;
    width: 10rem;
  }
  .text-center {
    text-align: center;
  } 
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }
  .text--white {
    color: aliceblue;
  }
  
  .full-width {
    width: 100%;
  }

  small {
    display: block;
  }

  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
    user-select: none;
  }
  
  label {
    color: ${theme.textGrey};
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
    
    a {
      color: ${theme.textGrey};
      font-family: 'Ropa Sans',sans-serif;
      font-size: 1.8rem;
      text-decoration: underline;
    }
  }
  
  .answers {
    margin: 2rem 0 2rem 0;
  }
  
  .fa-exclamation-circle {
    color: ${theme.warning}
  }
  
  .fa-ban {
    color: ${theme.error}
  }
  
  .fa-check-circle {
    color: ${theme.valid}
  } 
  
  .fa-info-circle {
    color: ${theme.textBlue}
  }
  
  .navigation {
    padding-bottom: 4rem;
  }
  
  input[type="checkbox"] {
      position: relative;
      margin-left: 0;
      margin-right: .75rem;
  
    &:before {
      content: ' ';
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      background-color: white;
      position: absolute;
      top: -.4rem;
      left: -.5rem;
      border: solid 1px ${theme.textBlue};
    }
    
    &:after {
      opacity: 0;
      transition: opacity .5s;
    }
    
    &:checked:after {
      content: '✓';
      opacity: 1;
      display: block;
      color: ${theme.textBlue};
      position: absolute;
      top: -.75rem;
      left: -.35rem;
      border-radius: 50%;
      font-size: 2rem;
      font-weight: 300;
    }
  }
  
  #rcc-confirm-button {
    font-size: 1.8rem;
    font-family: 'Ropa Sans', sans-serif;
    border-radius: ${theme.borderRadius} !important;
    padding: 1rem 6.0rem !important;
  }
  
`;
