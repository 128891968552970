import React from 'react';
import {StyledFooter} from "./Footer.styled";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();

    return (
        <StyledFooter>
            <a href="https://werkenoverdegrens.eu/" target={'_blank'}>
                <img src={t('footer.imgSrc')} alt={t('footer.imgAlt')}/>
            </a>
        </StyledFooter>
    )
};

export default Footer;
