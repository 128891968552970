import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import GenericPageElm from "../Styling/generic-page";
import {useTranslation} from "react-i18next";

function PrivacyDeclarationPage() {
    const {t} = useTranslation();
    return (
        <GenericPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.privacyDeclaration.title')}</h2>

                        <i>{t('pages.privacyDeclaration.i')}</i>

                        <p>{t('pages.privacyDeclaration.p1.1')} <a href="https://werkenoverdegrens.eu">https://werkenoverdegrens.eu</a> {t('pages.privacyDeclaration.p1.2')}:</p>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list1')}</li>
                            <li>{t('pages.privacyDeclaration.list2')}</li>
                            <li>{t('pages.privacyDeclaration.list3')}</li>
                            <li>{t('pages.privacyDeclaration.list4')}</li>
                            <li>{t('pages.privacyDeclaration.list5')}</li>
                        </ul>

                        <p>{t('pages.privacyDeclaration.p2')}</p>

                        <h3>1. {t('pages.privacyDeclaration.subtitle1')}</h3>
                        <strong>1.1 {t('pages.privacyDeclaration.secondSubtitle')}:</strong>

                        <p>{t('pages.privacyDeclaration.p3')}</p>

                        <strong>{t('pages.privacyDeclaration.strong1')}:</strong>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list6')}</li>
                            <li>{t('pages.privacyDeclaration.list7')}</li>
                        </ul>


                        <strong>{t('pages.privacyDeclaration.strong2')}:</strong>

                        <p>{t('pages.privacyDeclaration.p4')}</p>

                        <strong>{t('pages.privacyDeclaration.strong3')}</strong>

                        <p>{t('pages.privacyDeclaration.p5')}</p>

                        <strong>1.2 {t('pages.privacyDeclaration.secondSubtitle')}:</strong>

                        <p>{t('pages.privacyDeclaration.p6')}</p>

                        <strong>{t('pages.privacyDeclaration.strong1')}:</strong>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list6')}</li>
                            <li>{t('pages.privacyDeclaration.list7')}</li>
                        </ul>

                        <strong>{t('pages.privacyDeclaration.strong2')}:</strong>

                        <p>{t('pages.privacyDeclaration.p4')}</p>

                        <strong>{t('pages.privacyDeclaration.strong3')}</strong>

                        <p>{t('pages.privacyDeclaration.p5')}</p>

                        <strong>1.3 {t('pages.privacyDeclaration.secondSubtitle')}:</strong>

                        <p>{t('pages.privacyDeclaration.p7')}</p>

                        <strong>{t('pages.privacyDeclaration.strong1')}:</strong>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list10')}</li>
                            <li>{t('pages.privacyDeclaration.list11')}</li>
                            <li>{t('pages.privacyDeclaration.list12')}</li>
                            <li>{t('pages.privacyDeclaration.list13')}</li>
                        </ul>

                        <strong>{t('pages.privacyDeclaration.strong2')}:</strong>

                        <p>{t('pages.privacyDeclaration.p4')}</p>

                        <strong>{t('pages.privacyDeclaration.strong3')}</strong>

                        <p>{t('pages.privacyDeclaration.p5')}</p>

                        <strong>1.4 {t('pages.privacyDeclaration.secondSubtitle')}:</strong>

                        <p>{t('pages.privacyDeclaration.p8')}</p>

                        <strong>{t('pages.privacyDeclaration.strong1')}:</strong>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list10')}</li>
                            <li>{t('pages.privacyDeclaration.list11')}</li>
                            <li>{t('pages.privacyDeclaration.list12')}</li>
                            <li>{t('pages.privacyDeclaration.list13')}</li>
                        </ul>

                        <strong>{t('pages.privacyDeclaration.strong2')}:</strong>

                        <p>{t('pages.privacyDeclaration.p4')}</p>

                        <strong>{t('pages.privacyDeclaration.strong3')}</strong>

                        <p>{t('pages.privacyDeclaration.p5')}</p>

                        <h3>2. {t('pages.privacyDeclaration.subtitle2')}</h3>
                        <p>{t('pages.privacyDeclaration.p9')}</p>

                        <h3>3. {t('pages.privacyDeclaration.subtitle3')}</h3>
                        <p>{t('pages.privacyDeclaration.p10')}</p>

                        <p>{t('pages.privacyDeclaration.p11')}</p>

                        <p>{t('pages.privacyDeclaration.p12')}</p>

                        <p>{t('pages.privacyDeclaration.p13')}</p>

                        <h3>4. {t('pages.privacyDeclaration.subtitle4')}</h3>
                        <p>{t('pages.privacyDeclaration.p14')}</p>

                        <h3>5. {t('pages.privacyDeclaration.subtitle5')}</h3>
                        <p>{t('pages.privacyDeclaration.p15')}</p>

                        <h3>6. {t('pages.privacyDeclaration.subtitle6')}</h3>
                        <p>{t('pages.privacyDeclaration.p16')}</p>

                        <h3>7. {t('pages.privacyDeclaration.subtitle7')}</h3>
                        <p>{t('pages.privacyDeclaration.p17')}:</p>

                        <ul>
                            <li>{t('pages.privacyDeclaration.list14')}</li>
                            <li>{t('pages.privacyDeclaration.list15')}</li>
                            <li>{t('pages.privacyDeclaration.list16')}</li>
                            <li>{t('pages.privacyDeclaration.list17')}</li>
                            <li>{t('pages.privacyDeclaration.list18')}</li>
                            <li>{t('pages.privacyDeclaration.list19')}</li>
                        </ul>

                        <p>{t('pages.privacyDeclaration.p18')}</p>

                        <h3>8. {t('pages.privacyDeclaration.subtitle8')}</h3>
                        <p>{t('pages.privacyDeclaration.p19')}</p>

                        <h3>9. {t('pages.privacyDeclaration.subtitle9')}</h3>
                        <p>{t('pages.privacyDeclaration.contact1')} <br/>
                            {t('pages.privacyDeclaration.contact2')}<br/>
                            {t('pages.privacyDeclaration.contact3')}<br/>
                            {t('pages.privacyDeclaration.contact4')} <a href="https://werkenoverdegrens.eu">https://werkenoverdegrens.eu</a><br/>
                            {t('pages.privacyDeclaration.contact5')}: <a href="mailto: info@werkenoverdegrens.eu">info@werkenoverdegrens.eu</a></p>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </GenericPageElm>
    );
}

export default PrivacyDeclarationPage
