import styled from "styled-components";
import Page from "../Page";
import {theme} from "../../theme";

const GenericPageElm = styled(Page)`
  padding: 2rem;
  
  .container {
      max-width: 96rem;
      margin: 0 auto;
  }
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
  
  strong {
    font-weight: bold;
    font-size: 1.8rem;
  }
  
  ul li {
    color: ${theme.textGrey};
  }
`;

export default GenericPageElm;
