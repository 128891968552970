import styled from 'styled-components';

export const LanguageSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LanguageSwitchBtn = styled.button`
    font-family: 'Open Sans',sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: #0E3C72;
    text-decoration: none;
    background: transparent;
    border: none;
    margin-right: 1rem;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    &.is-active {
        text-decoration: underline;
    }
`;