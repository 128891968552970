import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledRadioButton = styled.div`
  display: inline-block;
  position: relative;
  margin: 1rem 0;
  text-align: left;
  cursor: pointer;
  user-select: none;
  color: ${theme.primaryLight};
  border-radius: .9rem;
 
  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }
  
  label {
    background-color: ${theme.primaryLight};
    color: ${theme.primaryDark};
    cursor: pointer;
    display: block;
    padding: 1.1rem 1rem 1.1rem 5.5rem;
    border-radius: ${theme.borderRadius};
    transition: all .2s;
    border: .2rem solid ${theme.primaryLight};
  }
 
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 45%;
      left: 3rem;
      height: 3.5rem;
      width: 3rem;
      pointer-events: none;
      //background-color: #eee;
      transform: translateY(-30%);
    }
    
    /* On mouse-over, add a grey background color */
    :hover input ~ .checkmark {
      //background-color: #ccc;
    }
    
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark:after {
      opacity: 1;
    }
    input:checked + label {
       background-color: ${theme.primaryLight};
       color: ${theme.primaryDark};
       border-color: ${theme.primaryDark};
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    :after {
      content: "";
      position: absolute;
      display: none;
      pointer-events: none;
    }
    
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .checkmark:after {
      opacity: 0;
      pointer-events: none;
      display: block;
      content: ' ';
      left: 1.8rem;
      top: 1rem;
      width: 1rem;
      height: 2rem;
      border: solid ${theme.primaryDark};
      border-width: 0 .2rem .2rem 0;
      transform: rotate(45deg);
      transition: opacity .5s;
    }
`;
