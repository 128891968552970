import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledInput = styled.div`
  margin: 2rem auto;
  max-width: 25rem;

    input {
        position: relative;
        font-family: 'Ropa Sans', sans-serif;
        display: flex;
        font-size: 1.8rem;
        color: ${theme.textBlue};
        padding: .6em 1.4em .5em .8em;
        border: 1px solid ${theme.textBlue};
        border-radius: 0;
        appearance: none;
        background-color: #fff;
        width: 100%;
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
          min-width: 100%;
        }
        
        :focus {
          outline: none;
        }
    }
`;
