import React from 'react';
import {StyledLightbox} from "./Lightbox.styled";

const Lightbox = ({open, title, body, close}) => {

    function closeLightbox() {
        close();
    }
    return (
        <StyledLightbox visible={open}>
            <div className="content">
                <div className="close" onClick={() => closeLightbox()}>✕</div>
                <p className="title">{title}</p>
                <div className="body" dangerouslySetInnerHTML={{__html: body}} />
            </div>
        </StyledLightbox>
    )
};

export default Lightbox;
