import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';

const renderReactDom = () => {
    ReactDOM.render(
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>,
      document.getElementById('root')
    );
  };
  
  if (window.cordova) {
    document.addEventListener('deviceready', () => {
      renderReactDom();
    }, false);
  } else {
    renderReactDom();
  }

// custom service worker and pwa install flow
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js', {scope: '.'}).then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
    });
  }

//   let deferredPrompt
  
//   window.addEventListener('beforeinstallprompt', (e) => {
//     // Prevent the mini-infobar from appearing on mobile
//     e.preventDefault();
//     // Stash the event so it can be triggered later.
//     deferredPrompt = e.currentTarget;
//     console.log(deferredPrompt)
//     deferredPrompt.prompt()
//     return false;
//   });

//   window.dispatchEvent(new Event('beforeinstallprompt'))
