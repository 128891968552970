import React from 'react';
import {StyledButton} from "./Button.styled";
const Button = ({disabled, secondary, start = false, large, fullWidth, children}) =>
        <StyledButton disabled={disabled} secondary={secondary} start={start.toString()} large={large} fullWidth={fullWidth}>
            <div className="button">
                    {children}
            </div>
        </StyledButton>
;
export default Button;
