import React from "react";
import styled from "styled-components";
import Page from "../Page"
import { Col, Row } from "react-styled-flexboxgrid";
import { useTranslation } from "react-i18next";

const AboutPageElm = styled(Page)`
padding: 2rem;
  
  .container {
      max-width: 96rem;
      margin: 0 auto;
  }
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
  .partner-logo-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 16rem;
  }
  
  .partner-logo {
        max-width: 9rem;
        display: flex;
        align-self: center;
        @media (min-width: ${({ theme }) => theme.mobile}) {
            max-width: 14rem;
        }
  }
`;

function AboutPage() {
    const { t, i18n } = useTranslation();
    return (
        <AboutPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.about.title')}</h2>
                        <p>{t('pages.about.p1')}<br />{t('pages.about.p2')}</p>
                        <p>{t('pages.about.p3')}</p>
                        <p>{t('pages.about.p4')} {i18n.language !== 'nl' && <a href="https://ec.europa.eu/social/easi">https://ec.europa.eu/social/easi</a>}</p>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <Row>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/IVR-WerkenOverDeGrens.svg'}
                                        alt="IVR Schelde-Kempen" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-ivr-maas-rijn.jpg'}
                                        alt="IVR Maas-Rijn" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-ivr-rijn-ijssel.jpg'}
                                        alt="IVR Schelde-Kempen" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-item.png'} alt="ITEM Maastricht" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/Viavictor_Logo_Logo_Zwart.svg'}
                                        alt="viavictor Creative Agency" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/europa-logo.png'} alt="Europa" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </AboutPageElm>
    );
}

export default AboutPage
