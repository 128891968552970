import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { Header, Body, Footer } from './components';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {HomePage, StartPage, AboutPage, QuizPage, ResultPage, MailFormPage} from './pages';
import MailConfirmPage from "./pages/MailConfirm";
import PrivacyPage from "./pages/Privacy";
import TermsAndConditionsPage from "./pages/TermsAndConditions";
import CookiePolicyPage from "./pages/CookiePolicy";
import PrivacyDeclarationPage from "./pages/PrivacyDeclaration";
import DisclaimerPage from "./pages/Disclaimer";
import CookieConsent from "react-cookie-consent";
import PartnersPage from "./pages/Partners";
import ContactPage from "./pages/Contact";
import TagManager from 'react-gtm-module';
import { withTranslation } from 'react-i18next';


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previousQuestion: '',
            answers: [],
            jobs: 1,
            currentJob: 1,
            scoreSet: {},
            context: {
                // "country": "fc6ad595-f72f-11ea-9892-005056930279",
                // "social_security": "fc6ad595-f72f-11ea-9892-005056930279",
                // "taxes": "55d3af5b-bf6f-11ea-b4e1-005056930279",
                // "jobs": "3"
            }
        }

        const tagManagerArgs = {
            gtmId: 'GTM-PZMWHB4'
        }
        TagManager.initialize(tagManagerArgs);
    }

    updatePreviousQuestion(previousQuestion) {
        this.setState({previousQuestion})
    }

    updateAnswers(answerID) {
        if (answerID) {
            let answers = Object.assign([], this.state.answers);
            answers.push(answerID)
            this.setState({answers})
        } else {
            let answers = Object.assign([], this.state.answers);
            answers.pop();
            this.setState({answers})
        }
    }

    updateScoreSet(scoreSet) {
        this.setState({scoreSet});
    }

    setJobsCount(jobs) {
        console.log('kom ik hier', jobs);
        this.setState({jobs});
    }

    setCurrentContext(context) {
        this.setState({context});
    }

    setCurrentJob(currentJob) {
        this.setState({currentJob});
    }

    render() {
        const { t } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyles />
                    <div>
                        <Header/>
                        <Body>
                            <CookieConsent
                                buttonText={t('cookie.button')}
                                cookieName="gdpr-cookie"
                                expires={150}
                                onAccept={() => {
                                    document.cookie = "gdpr-cookie=accepted";
                                }}
                            ><p className="text--white">{t('cookie.text')}</p></CookieConsent>
                            <Router>
                                <Route
                                    render={({ location }) => {
                                        return (
                                            <TransitionGroup component={null}>
                                                <CSSTransition
                                                    timeout={400}
                                                    classNames="page"
                                                    key={location.key}
                                                >
                                                    <Switch location={location}>
                                                        <Route exact path="/mail/" render={props =>
                                                            <MailFormPage
                                                                {...props}
                                                                scoreSet={this.state.scoreSet}
                                                                context={this.state.context}
                                                                answers={this.state.answers}
                                                            />
                                                        }/>
                                                        <Route exact path="/result/" render={props =>
                                                            <ResultPage
                                                                {...props}
                                                                scoreSet={this.state.scoreSet}
                                                                context={this.state.context}
                                                                answers={this.state.answers}
                                                            />
                                                        }/>
                                                        <Route exact path="/quiz/:id" render={props =>
                                                            <QuizPage
                                                                {...props}
                                                                jobs={this.state.jobs}
                                                                currentJob={this.state.currentJob}
                                                                setCurrentJob={currentJob => this.setCurrentJob(currentJob)}
                                                                previousQuestion={this.state.previousQuestion}
                                                                updateScoreSet={scoreSet => this.updateScoreSet(scoreSet) }
                                                                updateAnswers={answer => this.updateAnswers(answer) }
                                                                setPreviousQuestion={questionId => this.updatePreviousQuestion(questionId) }
                                                            />
                                                        } />
                                                        <Route path="/quiz/" render={props =>
                                                            <QuizPage
                                                                {...props}
                                                                jobs={this.state.jobs}
                                                                currentJob={this.state.currentJob}
                                                                setCurrentJob={currentJob => this.setCurrentJob(currentJob)}
                                                                previousQuestion={this.state.previousQuestion}
                                                                updateScoreSet={scoreSet => this.updateScoreSet(scoreSet) }
                                                                updateAnswers={answer => this.updateAnswers(answer) }
                                                                setPreviousQuestion={questionId => this.updatePreviousQuestion(questionId) }
                                                            />
                                                        }/>
                                                        <Route exact path="/mail-confirm" component={MailConfirmPage}/>
                                                        <Route exact path="/about" component={AboutPage}/>
                                                        <Route exact path="/contact" component={ContactPage}/>
                                                        <Route exact path="/partners" component={PartnersPage}/>
                                                        <Route exact path="/legal" component={PrivacyPage}/>
                                                        <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage}/>
                                                        <Route exact path="/cookie-policy" component={CookiePolicyPage}/>
                                                        <Route exact path="/privacy-declaration" component={PrivacyDeclarationPage}/>
                                                        <Route exact path="/disclaimer" component={DisclaimerPage}/>
                                                        <Route exact path="/start" render={() =>
                                                            <StartPage setJobsCount={count => this.setJobsCount(count)} setContext={context => this.setCurrentContext(context)}/>
                                                        } />
                                                        <Route exact path="/" component={HomePage} />
                                                    </Switch>
                                                </CSSTransition>
                                            </TransitionGroup>
                                        );
                                    }}
                                />
                            </Router>
                        </Body>
                        <Router>
                            <Route
                                render={({ location }) => {
                                    const mobileLimit = theme.mobile.replace('px', '');

                                    if (location.pathname.indexOf('quiz') === -1 || (mobileLimit < window.innerWidth)) {
                                        return (<Footer/>);
                                    }
                                }}
                            />
                            </Router>

                    </div>
                </>
            </ThemeProvider>
        );
    }
}

export default withTranslation()(App)
