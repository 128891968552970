import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import styled from "styled-components";
import Page from "../Page";
import {theme} from "../../theme";
import { useTranslation } from "react-i18next";

const PartnersPageElm = styled(Page)`
padding: 2rem;

  .container {
      max-width: 96rem;
      margin: 0 auto;
  }
  
  h2 {
  margin-bottom: 6rem;
  }
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
  
  strong {
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
    width: 100%;
    margin: 3rem 0;
  }
  
  ul li {
    color: ${theme.textGrey};
  }
  
  .partner-logo-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 18rem;
  }
  
  .partner-logo-wrapper p {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  
  
  .partner-logo {
    max-width: 15rem;
    display: flex;
    align-self: center;
  }
`;

function PartnersPage() {
    const {t} = useTranslation();
    return (
        <PartnersPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.partners.title')}:</h2>

                        <Row>
                            <Col xs={12} sm={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-syndicat-european.jpg'} alt="Europees Vakverbond (EVV)"/>
                                    <p>{t('pages.partners.evv')}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-eures.png'} alt="Eures"/>
                                    <p>{t('pages.partners.eures')}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="partner-logo-wrapper">
                                    <img className="partner-logo" src={'/images/logo-grensinfo-scheldemond.png'} alt="Grensinfopunt Scheldemond"/>
                                    <p>{t('pages.partners.grensinfopunt')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </PartnersPageElm>
    );
}

export default PartnersPage
