import React from 'react';
import {StyledBody} from "./Body.styled";


const Body = props =>
    <StyledBody>
          {props.children}
      </StyledBody>;

export default Body;
