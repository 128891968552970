import React from 'react';
import {StyledCardLink} from "./CardLink.styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/pro-regular-svg-icons";

const CardLink = ({title, description, link, logo}) => {
        return (<StyledCardLink>
            <a target="_blank" href={link}>
            <div className="cardlink">
                <div className="cardlink__logo">
                    <img src={logo} alt={title}/>
                </div>

                <div className="cardlink__title">
                    <strong>{title}</strong>
                </div>
                <div className="cardlink__arrow">
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>
             </div>
            </a>
    </StyledCardLink>)
}
;

export default CardLink;