import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

const Menu = ({ open, ...props }) => {

    const isHidden = !!open;
    const tabIndex = isHidden ? 0 : -1;
    const { t } = useTranslation();

    return (
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
            <p className={'mobile-title'} tabIndex={tabIndex}>
                {t('site.title')}
            </p>
            <a href="/" tabIndex={tabIndex}>
                <span>Home</span>
            </a>
            <a href="/start" tabIndex={tabIndex}>
                <span>{t('menu.start')}</span>
            </a>
            <a href="/about" tabIndex={tabIndex}>
                <span>{t('menu.about')}</span>
            </a>
            <a href="/partners" tabIndex={tabIndex}>
                <span>{t('menu.partners')}</span>
            </a>
            <a href="/legal" tabIndex={tabIndex}>
                <span>{t('menu.privacy')}</span>
            </a>
            <a href="/contact" tabIndex={tabIndex}>
                <span>{t('menu.contact')}</span>
            </a>
        </StyledMenu>
    )
};

Menu.propTypes = {
    open: bool.isRequired,
};

export default Menu;
