import styled from 'styled-components';
import {theme} from '../../theme';

const getButtonColor = (secondary, start) => {
    if (secondary) {
        return theme.primaryDark;
    }

    if (start === 'true') {
        return theme.primaryDark;
    }

    return "white";
};

const getButtonBackgroundColor = (secondary, start) => {
    if (secondary) {
        return "white";
    }

    if (start === 'true') {
        return theme.primaryLight;
    }

    return theme.primaryDark;
};

export const StyledButton = styled.div`
    .button {
    cursor: pointer;
    
      a, .a {
        color: ${props => getButtonColor(props.secondary, props.start)};
        background-color: ${props => getButtonBackgroundColor(props.secondary, props.start)};
        text-align: center;
        border-radius: ${theme.borderRadius}; 
        padding: ${ props => props.large ? "1rem 6.0rem" : ".5rem 3.0rem"};
        display: flex;
        align-self: center;
        -webkit-transition: .15s ease-in-out;
        transition: .15s ease-in-out;
        box-shadow: 0 .2rem .4rem 0 rgba(0,0,0,.24);
        border: none;
        
        opacity: ${props => props.disabled ? '.5' : '1'};
        pointer-events: ${props => props.disabled ? 'none' : 'all'};
        font-weight: 400;
        width: ${props => props.fullWidth ? '100%' : 'auto'};
        
        &:hover {
          transform: scale(1.025);
          transition: all .25s;
        }
        
        &.link {
          text-decoration: underline;
          background-color: transparent;
          box-shadow: none;
        }
       
      }
      span {
        color: ${props => getButtonColor(props.secondary, props.start)};
        display: block;
        text-align: center;
        align-self: center;
        margin-right: 1.5rem;
        width: ${props => props.fullWidth ? '100%' : 'auto'};
        // text-align: ${props => props.fullWidth ? 'center' : 'auto'};
      }
      .svg-inline--fa {
          width: 2rem;
          margin: 0;
          display: flex;
          align-self: center;
        }
    }
`;
