import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledFooter = styled.div`
  padding: 1rem;
  height: ${theme.footerHeight};
  text-align: center;
  line-height: ${theme.footerHeight};

  img {
    max-height: calc(${theme.footerHeight} - 2.5rem );
    height: 100%;
    width: auto;
    //filter: saturate(0%);
    opacity: .8;
    transition: all 1s;
    
    &:hover {
        filter: saturate(100%);
        opacity: 1;
    }
  }
`;
