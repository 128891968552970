import { Button, Modal } from "../../components";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Page from "../Page";
import PageGrid from "../../components/PageGrid";
import { theme } from "../../theme";
import { withTranslation, i18n } from 'react-i18next';

const HomePageElm = styled(Page)`
  background-color: ${({ theme }) => theme.primaryDark};
  
  .vertical-align {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    /*height: calc(100vh - ${theme.headerHeight} - ${theme.footerHeight});*/
    
    .onboarding {
        margin: 2rem 0rem;
       
        .step {
            margin-bottom: 5rem;
            img {
                margin:1rem auto;
                display:block;
            }
            p {
                font-size: 1.8em;
            }
        }
    }
    .start {
        margin: 2rem 0rem;
        .button a {
            padding:2rem 6rem;
            font-size: 2rem;
        }
    }
    .disclaimer {
        margin: 2rem 0rem;
        p {
           
            color: aliceblue;
            a {
                color: aliceblue;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
    .cta {
        background-color: #2962a5 ;
        margin: 5rem 0rem;
        padding: 4rem 2rem;
        border-radius: 10px;
        color: ${theme.primaryLight};
        h1 {
            font-size: 3em;
            text-align:left;
            font-weight: bold;
            color: ${theme.primaryLight};
        }
        p {
            font-size:2em;
            font-weight:bold;
            color: ${theme.primaryLight};
        }
        a {
            font-size:1em;
            text-decoration: underline;
            color: ${theme.primaryLight};
        }
        
    }
  }
`;

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getBlock() {
        const { t } = this.props
        const { i18n } = this.props;
        console.log(i18n.language);
        if (i18n.language === "nl" || i18n.language === "fr" || i18n.language === "de") {
        } else {
            i18n.changeLanguage("nl");
        }
        if (i18n.language === 'nl') {
            return <div className="cta text-left">
                <h1>{t('pages.home.moreInfo1')} <br />{t('pages.home.moreInfo2')}</h1>
                <p>
                    {t('pages.home.go')} <a href="https://www.werkenoverdegrens.eu" title="Werken over de grens">www.werkenoverdegrens.eu</a>.
                </p>
            </div>
        }
    }

    canStart = true;

    render() {
        const { t, i18n } = this.props;
        return (
            <HomePageElm>
                <Modal></Modal>
                <PageGrid>
                    <div className="vertical-align">
                        <div className="onboarding">
                            <div className="step">
                                <img src={'images/IVR-iconen_Huisje.svg'} alt="Stap 1 - je situatie" />
                                <p className="text--white text-center">
                                    1. {t('pages.home.situation')}
                                </p>
                            </div>
                            <div className="step">
                                <img src={'images/IVR-iconen_Babbel.svg'} alt="Stap 2 - beantwoord de vragen" />
                                <p className="text--white text-center">
                                    2. {t('pages.home.questions')}
                                </p>
                            </div>
                            <div className="step">
                                <img src={'images/IVR-iconen_Evaluatie.svg'} alt="Stap 3 - het resultaat" />
                                <p className="text--white text-center">
                                    3. {t('pages.home.result')}.
                                </p>
                            </div>
                        </div>

                        <div className="start text-center">
                            <Button disabled={!this.canStart} large={true} fullWidth={true} start={true}>
                                <Link to="/start" onClick={() => { window.location.href = "/start" }}>
                                    <span>{t('pages.home.start')}</span>
                                </Link>
                            </Button>
                        </div>

                        {this.getBlock()}

                        <div className="disclaimer">
                            <h2 className="text--white text-left">{t('pages.home.subtitle')}</h2>
                            <p>{t('pages.home.p1')}</p>
                            <p>{t('pages.home.pextra')}</p>
                            {i18n.exists('pages.home.p2') && <p>{t('pages.home.p2')}</p>}
                            {i18n.exists('pages.home.p3') && <p>{t('pages.home.p3')}</p>}
                            <p>{t('pages.home.p4')}</p>
                            <p>{t('pages.home.p5')}</p>
                            <p>{t('pages.home.p6')}</p>
                            <p>
                                {t('pages.home.p7')} <a href="mailto:info@werkenoverdegrens.eu">info@werkenoverdegrens.eu</a> {t('pages.home.email')}.
                            </p>
                            <p>{t('pages.home.p8')}</p>

                        </div>

                    </div>
                </PageGrid>
            </HomePageElm>
        );
    }
}

export default withTranslation()(HomePage);
