import React from 'react';
import {StyledRadioButton} from "./RadioButton.styled";

const RadioButton = ({name, index, value, updateValue}) =>
        <StyledRadioButton>
                <input type="radio" id={`option_${index}`} name="list" onChange={() => updateValue(value)}/>
            <label htmlFor={`option_${index}`}>{name}</label>
            <span className="checkmark">
                </span>
        </StyledRadioButton>
;

export default RadioButton;
