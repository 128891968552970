import styled from 'styled-components';

export const StyledCardLink = styled.div`
  .cardlink {
    border-bottom: 1px solid #cfd0d2;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: normal;
    padding: 1.0rem 0rem;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .3s ease-in-out;
    
    &:hover {
        background-color: #f5f5f5;
    }
   
    .cardlink__logo {
        
        max-width:120px;
        
        img {
            max-height:35px;
        }
    }
    
    .cardlink__title {
        display: flex;
        margin-left: 1rem;
        
    }
    .cardlink__arrow {
        align-self: center;
        margin-left: auto;
    }
    
    .svg-inline--fa {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-self: flex-start;
      margin-left: 1rem;
      margin-right: 1rem;
      align-self: center;
    }
    strong {
      display: flex;
      font-size: 1.9rem;
      align-self: center;
    }
  }
  
  .accordion__body {
    padding-left: 2.5rem;
    
    p {
      color: #717477;
    }
  }
`;
