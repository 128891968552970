import React from 'react';
import {Col, Grid, Row} from "react-styled-flexboxgrid";
import styled from "styled-components";
import {theme} from "../../theme";

const GridContentElm = styled.div`
  display: flex;
  align-self: center;  
    align-content: center;
    justify-content: center;
    flex-direction: column;
    //;
    // eslint-disable-next-line no-undef
    min-height: calc(100vh - ${theme.headerHeight} - ${theme.footerHeight});
    //height: calc(100vh - ${theme.headerHeight} - ${theme.footerHeight});
    overflow: visible;
    
    // &.isQuiz {
    //     display: none;
    //     height: calc(100vh - ${theme.headerHeight} - ${theme.footerHeight} - 50px);
    //     padding-bottom: 6rem;
    // }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        justify-content: flex-start;
        margin-top: 3vh
      }
`;


const PageGrid = props =>
    <Grid>
          <Row>
                <Col xs={0} md={3}>
                </Col>
                <Col xs={12} md={6}>
                      <GridContentElm>
                            {props.children}
                      </GridContentElm>
                </Col>
                <Col xs={0} md={3}>
                </Col>
          </Row>
    </Grid>;

export default PageGrid;
