import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import GenericPageElm from "../Styling/generic-page";
import { useTranslation } from "react-i18next";



function DisclaimerPage() {
    const {t} = useTranslation();
    return (
        <GenericPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.disclaimer.title')}</h2>

                        <p>{t('pages.disclaimer.p1')}: <a
                            href="mailto:info@werkenoverdegrens.eu">info@werkenoverdegrens.eu</a>.</p>

                        <p>{t('pages.disclaimer.p2')}</p>

                        <p>{t('pages.disclaimer.p3')}</p>

                        <p>{t('pages.disclaimer.p4')}</p>

                        <p>{t('pages.disclaimer.p5')}</p>

                        <p>{t('pages.disclaimer.p6')}</p>

                        <p>{t('pages.disclaimer.p7')}</p>

                        <p>{t('pages.disclaimer.p8')}</p>

                        <p>{t('pages.disclaimer.p9')}</p>

                        <p>{t('pages.disclaimer.p10')}</p>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </GenericPageElm>
    );
}

export default DisclaimerPage
