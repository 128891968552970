import React from "react";
import styled from "styled-components";
import Page from "../Page"
import {Col, Row} from "react-styled-flexboxgrid";
import {Link} from "react-router-dom";
import {Button} from "../../components";
import { useTranslation } from "react-i18next";

const AboutPageElm = styled(Page)`
padding: 2rem;
  
  .container {
      max-width: 96rem;
      margin: 0 auto;
  }
  
  img {
    display: inline;
    margin: 1rem auto .5rem auto;
    max-width: 400px;
    width: 100%;
  }
  
  .disclaimer-buttons {
    margin: 10vh auto 0 auto;
    max-width: 50rem;
  
    .button {
      margin: 2rem 0;
    }
  }
`;

function PrivacyPage() {
    const {t} = useTranslation();
    return (
        <AboutPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.privacy.title')}</h2>
                        <div className="disclaimer-buttons">
                            <Button large={true} fullWidth={true}>
                                <Link to="/terms-and-conditions">
                                    <span>{t('pages.privacy.general')}</span>
                                </Link>
                            </Button>
                            <Button large={true} fullWidth={true}>
                                <Link to="/privacy-declaration">
                                    <span>{t('pages.privacy.privacy')}</span>
                                </Link>
                            </Button>
                            <Button large={true} fullWidth={true}>
                                <Link to="/disclaimer">
                                    <span>{t('pages.privacy.disclaimer')}</span>
                                </Link>
                            </Button>
                        </div>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </AboutPageElm>
    );
}

export default PrivacyPage
