import {Button, Input} from "../../components";
import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Page from "../Page"
import PageGrid from "../../components/PageGrid";
import {Col, Row} from "react-styled-flexboxgrid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons";
import * as SessionService from "../../services/session.service";
import { withTranslation } from 'react-i18next';

const MailFormPageElm = styled(Page)`
  .svg-inline--fa {
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
  }
`;

class MailFormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legal: false,
            answers: props.answers,
            context: props.context,
            scoreSet: props.scoreSet,
            questions: [
                {
                    label: 'Voornaam (*)',
                    key: 'firstname'
                },
                {
                    label: 'Achternaam (*)',
                    key: 'lastname'
                },
                {
                    label: 'E-mail (*)',
                    key: 'email'
                }
            ],
            form: {
                firstname: '',
                lastname: '',
                email: ''
            },
        };
    }



    canStart = false;

    updateQuestion(key, value) {
        this.validate(key, value);
    }

    validate(key, value) {
        let result = false;
        let form = this.state.form;

        switch (key) {
            case 'firstname':
                result = this.validateAlphaNumeric(value);
                break;
            case 'lastname':
                result = this.validateAlphaNumeric(value);
                break;
            case 'email':
                result = this.validateEmail(value);
                break;
            default:
                console.trace('unknown key for validator');
                break;
        }

        if (result) {
            form[key] = value
        } else {
            form[key] = ''
        }
        this.setState({form});
    }

    validateAlphaNumeric(value) {
        const exp = /^[A-ZÀ-Ýa-zà-ý0-9_0-9_\-\s]+$/;
        return (value.match(exp) && (value.length >= 1));
    }

    validateEmail(value) {
        // eslint-disable-next-line no-useless-escape
        const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value.match(exp);
    }

    validateLegal() {
        this.setState({legal: !this.state.legal})
    }

    sendMail() {
        const session = {
            "email" : this.state.form.email,
            "name" : this.state.form.lastname,
            "firstName" : this.state.form.firstname,
            "laborLawCountryTC": this.state.scoreSet.labor_law_country.name,
            "socialInsuranceCountryTC": this.state.scoreSet.social_insurance_country.name,
            "taxCountryTC": this.state.scoreSet.tax_country.name,
            "jobsCount": this.state.context.jobs,
            "language": "nl",
            "answers": [
                {
                    "ids": this.state.answers,
                    "scoreSet": this.state.scoreSet.id
                }
            ]
        };
        this._postSession(session);
    }
    _postSession(session) {
        SessionService.postSession(session).then((resp) => console.log(resp));
    }

    render() {
        const { t } = this.props;
        return (
            <MailFormPageElm>
                <PageGrid>
                    <p className="text--center">{t('pages.mailForm.p1')}</p>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p className="blue-text text-center">{t('pages.mailForm.p2')}</p>

                    <div className="answers">
                        <Row>
                            <Col xs={0} sm={1}>
                            </Col>
                            <Col xs={12} sm={10}>
                                { this.state.questions.map(question => <Input key={`answer_${question.key}`} type={question.key} label={question.label} question={question.key} updateAnswer={this.updateQuestion.bind(this)}/>)}
                            </Col>
                            <Col xs={0} sm={1}>
                            </Col>
                        </Row>
                    </div>

                    <div className="text-center">
                        <Row>
                            <Col xs={0} sm={1}>
                            </Col>
                            <Col xs={12} sm={10}>
                                <div className="gdpr-notice">
                                    <label htmlFor="gdpr"  >
                                        <input type="checkbox" id={'gdpr'} checked={this.state.legal} onChange={this.validateLegal.bind(this)} /> {t('pages.mailForm.checkbox')} <a href="/">{t('pages.mailForm.conditions')}</a> {t('pages.mailForm.and')} <a href="/">{t('pages.mailForm.privacy')}</a>
                                    </label>
                                </div>
                            </Col>
                            <Col xs={0} sm={1}>
                            </Col>
                        </Row>

                        <br/>
                        <Button disabled={!(!!this.state.form.firstname && !!this.state.form.lastname && !!this.state.form.email && this.state.legal)} large={true} fullWidth={true}>
                            <Link to="/mail-confirm" onClick={() => {this.sendMail()}}>
                                <span>{t('pages.mailForm.send')}</span>
                            </Link>
                        </Button>
                    </div>
                </PageGrid>
            </MailFormPageElm>
        );
    }
}

export default withTranslation()(MailFormPage);
