export const getQuestions = (language) => {
    //const url = `http://${process.env.REACT_APP_API_URL}/questions/?language=${language}`;
    const url = `${process.env.REACT_APP_API_URL}/questions/?language=${language}`;
    return callApi(url);
};

export const getQuestionByAnswer = (answerID, language) => {
    //const url = `http://${process.env.REACT_APP_API_URL}/questions/?answerID=${answerID}&language=${language}`;
    const url = `${process.env.REACT_APP_API_URL}/questions/?answerID=${answerID}&language=${language}`;
    return callApi(url)
};

const callApi = (url, options) => {
    const DEFAULT_OPTIONS =  {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return fetch(url, {...DEFAULT_OPTIONS, ...options} ).then((res) => res.json());
};
