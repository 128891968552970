import styled from 'styled-components';
import {theme} from '../../theme';

export const StyledBody = styled.div`
  background-color: #fff;
  position: relative;
  height: calc(100vh - (${theme.headerHeight} + ${theme.footerHeight}));
  // height: calc(100vh - (${theme.footerHeight}));
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: calc(100vh - (${theme.headerHeight} + ${theme.footerHeight}));
  }
  
  overflow-x: hidden;
  width: 100vw;
`;
