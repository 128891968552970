import React from 'react';
import {bool, func} from 'prop-types';
import {StyledBurger, Language, LanguageWrapper} from './Burger.styled';
import { useTranslation } from "react-i18next";
import {LanguageSwitchBtn, LanguageSwitchWrapper} from "../LanguageSelector/LanguageSelector.styled";

const Burger = ({open, setOpen, ...props}) => {

    const isExpanded = !!open;
    let menulang = true;
    if (window.location.pathname.includes("/quiz") || window.location.pathname.includes("/start") || window.location.pathname.includes("/result")) {
        menulang = false;
    }
    console.log(menulang);

    const {i18n} = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    if (menulang) {
        return (
            <div>
                <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} open={open}
                              onClick={() => setOpen(!open)} {...props}>
                    <span/>
                    <span/>
                    <span/>

                </StyledBurger>
                <LanguageWrapper>
                    <Language className={`${i18n.language === "nl" ? "is-active" : ""}`}
                              onClick={() => changeLanguage("nl")}>nl</Language>
                    <Language className={`${i18n.language === "fr" ? "is-active" : ""}`}
                              onClick={() => changeLanguage("fr")}>fr</Language>
                    <Language className={`${i18n.language === "de" ? "is-active" : ""}`}
                              onClick={() => changeLanguage("de")}>de</Language>
                </LanguageWrapper>
            </div>
        );
    } else {
            return (
                <div>
                    <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} open={open}
                                  onClick={() => setOpen(!open)} {...props}>
                        <span/>
                        <span/>
                        <span/>

                    </StyledBurger>
                </div>
            )
        }
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default Burger;
