import React from "react";
import {Col, Row} from "react-styled-flexboxgrid";
import GenericPageElm from "../Styling/generic-page";
import {useTranslation} from "react-i18next";


function TermsAndConditionsPage() {
    const {t} = useTranslation();
    return (
        <GenericPageElm>
            <div className="container">
                <Row>
                    <Col xs={0} sm={1}>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>{t('pages.terms.title')}</h2>
                        <p>{t('pages.terms.p1')}</p>

                        <p>{t('pages.terms.p2')}:</p>

                        <ul>
                            <li>{t('pages.terms.list1')}</li>
                            <li>{t('pages.terms.list2')}</li>
                            <li>{t('pages.terms.list3')}</li>
                        </ul>

                        <p><strong>{t('pages.terms.subtitle1')}</strong></p>

                        <p>{t('pages.terms.p3')}</p>

                        <p>{t('pages.terms.p4')}</p>

                        <p><strong>{t('pages.terms.subtitle2')}</strong></p>

                        <p>{t('pages.terms.p5')}</p>

                        <p>{t('pages.terms.p6')}</p>

                        <p>{t('pages.terms.p7')}</p>

                        <p><strong>{t('pages.terms.subtitle3')}</strong></p>

                        <p>{t('pages.terms.p8')}</p>

                        <p><strong>{t('pages.terms.subtitle4')}</strong></p>

                        <p>{t('pages.terms.p9')}</p>

                        <p><strong>{t('pages.terms.subtitle5')}</strong></p>

                        <p>{t('pages.terms.p10')}</p>

                        <p><strong>{t('pages.terms.subtitle6')}</strong></p>

                        <p>{t('pages.terms.p11')}</p>

                        <p><strong>{t('pages.terms.subtitle7')}</strong></p>

                        <p>{t('pages.terms.p12')}, <a href="https://.privacycommission.be">www.privacycommission.be</a></p>
                    </Col>
                    <Col xs={0} sm={1}>
                    </Col>
                </Row>
            </div>
        </GenericPageElm>
    );
}

export default TermsAndConditionsPage
