import React from 'react';
import { Button } from "../../components";
import { StyledModal } from "./Modal.styled";
import {withTranslation} from "react-i18next";

class Modal extends React.Component {
        constructor(props) {
                super(props);
                const pwaDismissed = document.cookie.indexOf('pwa=no') !== -1;
                this.state = {
                        show: this.needsToSeePrompt() && !pwaDismissed
                }
        }

        needsToSeePrompt() {
                return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        dismiss() {
                document.cookie = 'pwa=no';
                this.setState({
                        show: false
                });
        }

        render() {
                const {t} = this.props;
                const content = <>
                {this.state.show ? (
                        <StyledModal>
                        <div className="content">
                                <img className="pwa-icon" src={'/android-chrome-512x512.png'} alt="iOS PWA icon"/>
                                <h2>{t('modal.title')}</h2>
                                <p>{t('modal.description')}</p>
                                <p>{('modal.click1')}                                     <img className="share-icon" src={'/images/share.png'} alt="iOS share icon"/> {t('modal.click2')}</p>
                                <div className="text-center" onClick={this.dismiss.bind(this)}>
                                        <Button large={true} fullWidth={true} start={true} >
                                                <a>
                                                        <span>{t('modal.close')}</span>
                                                </a>
                                        </Button>
                                </div>
                        </div>
                        </StyledModal>
                      ) : ''}
                </>
                return content;
        }
}

export default withTranslation()(Modal);
