import {Button, Select, Lightbox, Input} from "../../components";
import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Page from "../Page"
import PageGrid from "../../components/PageGrid";
import {Col, Row} from "react-styled-flexboxgrid";
import * as DataService from "../../services/data.service";
import * as GeocodeService from "../../services/geocode.service";
import Spinner from "../../components/Spinner";
import {withTranslation} from "react-i18next";

const StartPageElm = styled(Page)`
`;

class StartPage extends React.Component {
    constructor(props) {
        super(props);
        const {i18n} = this.props;

        DataService.getParameterCountries(i18n.language).then((countries) => {
            const questions = [
                {
                    label: i18n.t('pages.start.label1'),
                    key: 'country',
                    answers: [
                        {value: '0', name: i18n.t('pages.start.default')},
                        ...this._parseCountries(countries)
                    ],
                },
                {
                    label: i18n.t('pages.start.label2'),
                    key: 'social_security',
                    answers: [
                        {value: '0', name: i18n.t('pages.start.default')},
                        ...this._parseCountries(countries)
                    ],
                },
                {
                    label: i18n.t('pages.start.label3'),
                    key: 'taxes',
                    answers: [
                        {value: '0', name: i18n.t('pages.start.default')},
                        ...this._parseCountries(countries)
                    ],
                },
                // {
                //     label: 'Ik heb momenteel ... jobs:',
                //     key: 'jobs',
                //     answers: [
                //         {value: '1', name: '1'},
                //         {value: '2', name: '2'},
                //         {value: '3', name: '3'}
                //     ],
                // },
                //{
                //    label: 'Mijn huidige postcode is:',
                //    key: 'postalcode',
                //}
            ];
            this.setState({questions});
            this.setState({ready: true})
        });

        this.state = {
            language: i18n.language,
            ready: false,
            lightboxes: {
                country: false,
                social_security: false,
                taxes: false,
                jobs: false,
            },
            questions: [],
            answers: {
                postalcode: '',
                jobs: 1
            }
        };
    }

    componentDidMount() {
        /* TODO: VGT heeft dit uitgeschakelt wegens problemen op iOS + Safari.
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                const location = position ? position.coords : false;
                if (location) {
                    GeocodeService.getPostcodeByLatLong(location.latitude, location.longitude).then(adres => {
                        if (adres && adres.features && adres.features[0]) {
                            this._setAnswer('postalcode', adres.features[0].properties.postcode);
                        }
                    })
                }
            });
        } else {
            console.log("Not Available");
        }
        */
    }

    canStart = true; // TODO SHOULD BE FALSE

    updateQuestion(key, value) {
        const answers = this._setAnswer(key, value);
        console.log(key);
        if(key === 'jobs') {
            this.props.setJobsCount(1);
        }
        this.props.setContext(answers);
        this.validate();
    }

    validate() {
        const validAnswers = this.state.questions.filter(q => (this.state.answers[q.key] && this.state.answers[q.key] !== ''));
        this.canStart = this.state.questions.length === validAnswers.length;
    }

    openLightbox(id) {
        this._setLightboxState(id, true)
    }

    closeLightbox(id) {
        this._setLightboxState(id, false)
    }

    _setAnswer(key, value) {
        const answers = Object.assign(this.state.answers, {[key]: value});
        this.setState({answers});
        return answers;
    }

    _setLightboxState(id, open) {
        let lightboxes = this.state.lightboxes;
        lightboxes[id] = open;
        this.setState({lightboxes});
    }

    _parseCountries(countries) {
        return countries.map(country => ({
            value: country.id,
            name: country.name,
        }))
    }

    _renderQuestions(questions) {
        const {t} = this.props;
        return (questions || []).map(question => {
            if (question.answers) {
                return <Select key={`answer_${question.key}`} label={question.label} question={question.key} options={question.answers} updateAnswer={this.updateQuestion.bind(this)} openLightbox={this.openLightbox.bind(this)} placeholder={t('pages.start.placeholder')}/>;
            } else {
                return <Input key={`answer_${question.key}`} value={this.state.answers.postalcode} label={question.label} question={question.key} options={question.answers} updateAnswer={this.updateQuestion.bind(this)} placeholder={t('pages.start.placeholder')} type="text"/>;
            }
        })
    }

    render() {
        const {t} = this.props;
        const content = this.state.ready ? (
            <>
                <p className="blue-text text-center">
                    {t('pages.start.p1')}
                </p>
                <div className="answers">
                    <Row>
                        <Col xs={0}>
                        </Col>
                        <Col xs={12}>
                            {this._renderQuestions(this.state.questions)}
                        </Col>
                        <Col xs={0}>
                        </Col>
                    </Row>
                </div>
                <div className="navigation text-center">
                    <Button disabled={!this.canStart} large={true} fullWidth={true}>
                        <Link to={`/quiz/?language=${this.state.language}`}>
                            <span>{t('pages.start.confirm')}</span>
                        </Link>
                    </Button>
                    <p>- {t('pages.start.p2')} -</p>
                    <a className="link" href={`/quiz?language=${this.state.language}`}>{t('pages.start.skip')} →</a>
                </div>
            </>
        ) : <Spinner />;

        return (
            <StartPageElm>
                <PageGrid>
                    { content }
                </PageGrid>
                <Lightbox open={this.state.lightboxes.country} title={'titel arbeid'} body={'<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel est auctor enim dictum auctor ac ac orci. Curabitur tincidunt mi at consectetur sodales. Curabitur in neque vitae neque ultrices eleifend sed ac felis.<br/> Vestibulum fringilla, sapien nec eleifend efficitur, lorem urna efficitur ipsum, eget semper magna orci vel ipsum. Nullam sagittis imperdiet mi, non lacinia nisi condimentum ac. Fusce ac felis eu sapien pellentesque elementum. Donec tincidunt tortor purus, et egestas ex lobortis sit amet. Praesent elementum eros mauris, at aliquet dui lacinia in. Sed imperdiet consectetur diam ac placerat.<br />Aenean vitae suscipit ipsum. Donec dapibus tellus ligula, non auctor lacus pellentesque eu. Etiam eu ligula rutrum, facilisis neque varius, pellentesque mi.</p>'} close={() => this.closeLightbox('country')}/>
                <Lightbox open={this.state.lightboxes.social_security} title={'titel social'} body={'<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel est auctor enim dictum auctor ac ac orci. Curabitur tincidunt mi at consectetur sodales. Curabitur in neque vitae neque ultrices eleifend sed ac felis.<br/> Vestibulum fringilla, sapien nec eleifend efficitur, lorem urna efficitur ipsum, eget semper magna orci vel ipsum. Nullam sagittis imperdiet mi, non lacinia nisi condimentum ac. Fusce ac felis eu sapien pellentesque elementum. Donec tincidunt tortor purus, et egestas ex lobortis sit amet. Praesent elementum eros mauris, at aliquet dui lacinia in. Sed imperdiet consectetur diam ac placerat.<br />Aenean vitae suscipit ipsum. Donec dapibus tellus ligula, non auctor lacus pellentesque eu. Etiam eu ligula rutrum, facilisis neque varius, pellentesque mi.</p>'} close={() => this.closeLightbox('social_security')}/>
                <Lightbox open={this.state.lightboxes.taxes} title={'titel taxes'} body={'<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel est auctor enim dictum auctor ac ac orci. Curabitur tincidunt mi at consectetur sodales. Curabitur in neque vitae neque ultrices eleifend sed ac felis.<br/> Vestibulum fringilla, sapien nec eleifend efficitur, lorem urna efficitur ipsum, eget semper magna orci vel ipsum. Nullam sagittis imperdiet mi, non lacinia nisi condimentum ac. Fusce ac felis eu sapien pellentesque elementum. Donec tincidunt tortor purus, et egestas ex lobortis sit amet. Praesent elementum eros mauris, at aliquet dui lacinia in. Sed imperdiet consectetur diam ac placerat.<br />Aenean vitae suscipit ipsum. Donec dapibus tellus ligula, non auctor lacus pellentesque eu. Etiam eu ligula rutrum, facilisis neque varius, pellentesque mi.</p>'} close={() => this.closeLightbox('taxes')}/>
                <Lightbox open={this.state.lightboxes.jobs} title={'titel jobs'} body={'<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel est auctor enim dictum auctor ac ac orci. Curabitur tincidunt mi at consectetur sodales. Curabitur in neque vitae neque ultrices eleifend sed ac felis.<br/> Vestibulum fringilla, sapien nec eleifend efficitur, lorem urna efficitur ipsum, eget semper magna orci vel ipsum. Nullam sagittis imperdiet mi, non lacinia nisi condimentum ac. Fusce ac felis eu sapien pellentesque elementum. Donec tincidunt tortor purus, et egestas ex lobortis sit amet. Praesent elementum eros mauris, at aliquet dui lacinia in. Sed imperdiet consectetur diam ac placerat.<br />Aenean vitae suscipit ipsum. Donec dapibus tellus ligula, non auctor lacus pellentesque eu. Etiam eu ligula rutrum, facilisis neque varius, pellentesque mi.</p>'} close={() => this.closeLightbox('jobs')}/>
            </StartPageElm>
        );
    }
}

export default withTranslation()(StartPage);
